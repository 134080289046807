import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { login, reset, updateUserRole } from "../../features/auth/authSlice";
import Logo from "../../assets/JUNE-BGR.png";

const defaultTheme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "filled",
        InputProps: {
          disableUnderline: true,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "9px",
          "&:before": {
            display: "none",
          },
          "&:after": {
            display: "none",
          },
          "&.Mui-focused": {
            boxShadow: `0 0 0 1px #000000`,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "9px",
        },
      },
    },
  },
});

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const { email, password } = formData;
  const { user, role, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    const storedUserRole = localStorage.getItem("userRole");
    if (storedUserRole) {
      dispatch(updateUserRole(storedUserRole));
    }
    if (isSuccess || user) {
      if (role && role.includes("WAREHOUSE_CLERK")) {
        navigate("/warehouse/dash");
      } else if (role && role.includes("CUSTOMER")) {
        navigate("/customer/dash");
      } else if (role && role.includes("COURIER")) {
        navigate("/courier/dash");
      } else if (role && role.includes("ADMIN")) {
        navigate("/admin");
      } else {
        navigate("/unauthorized");
      }
    }
    dispatch(reset());
    setEmailError("");
  }, [user, isError, isSuccess, message, navigate, dispatch, role]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setEmailError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const resultAction = await dispatch(login({ email, password }));

    if (login.fulfilled.match(resultAction)) {
      // Handle successful login
    } else if (login.rejected.match(resultAction)) {
      let errorMessage = resultAction.payload;

      // Map backend error to a user-friendly message
      if (errorMessage.includes("foreign key constraint fails")) {
        errorMessage =
          "We couldn't log you in. Please check your email and password.";
      } else if (errorMessage.includes("Cannot add or update a child row")) {
        errorMessage =
          "There was an issue processing your request. Please try again.";
      } else if (errorMessage.includes("Network Error")) {
        errorMessage =
          "Unable to connect to the server. Please check your internet connection.";
      } else {
        errorMessage = "An unexpected error occurred. Please try again later.";
      }

      setEmailError(errorMessage);
      toast.error(errorMessage);
    }
  };


  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{
              width: "150px",
              height: "auto",
              marginBottom: "20px",
            }}
          />
          <Typography variant="h4" fontWeight={1000} gutterBottom>
            Ship your lifestyle
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={onChange}
              error={!!emailError}
              helperText={emailError}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={onChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              sx={{
                mt: 3,
                mb: 2,
                fontSize: "large",
                textTransform: "none",
                height: "50px",
              }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : "SIGN IN"}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/password/reset/email" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to="/customer/register"
                  variant="body2"
                  style={{
                    textDecoration: "underline",
                    color: "#1976d2",
                    fontSize: 13,
                  }}
                >
                  Don't have an account? Create Account
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}