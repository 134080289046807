import React, { useState, useEffect } from "react";
import "./NavAppCustomer.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Logo from "../../assets/JUNE-LOGO-3.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { logout, reset } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import axios from "axios";

const NavAppCustomer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const token = useSelector((state) => state.auth.user?.accessToken ?? "");

  useEffect(() => {
    if (token) {
      //Decode the JWT token
      const decodedToken = jwt_decode(token);

      //Make a GET request to fetch user data by email
      axios
        .get(`/api/v1/user/get/by/email?email=${decodedToken.userEmail}`)
        .then((response) => {
          const userDataFromApi = response.data;
          setUserData(userDataFromApi);
        })
        .catch((error) => {
          console.error("Error fetching user data: ", error);
        });
    }
  }, [token]);

  const logoutHandler = async () => {
    try {
      await dispatch(logout()).unwrap();
      await dispatch(reset());
      // After successful logout, then navigate to login or do a window.location.href if you want a hard refresh.
      navigate("/login");
    } catch (error) {
      console.error("An error occurred during logout: ", error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="customer-sidebar">
      <div className="customer-sidebar__logo">
        <Link to="/">
          <img src={Logo} alt="JUNE" />
        </Link>
      </div>
      {/* <hr /> */}
      <div className="customer-sidebar__nav">
        <ul className="customer-sidebar__nav-list">
          {/* <p className="title">MAIN</p> */}
          <Link to="/customer/dash" className="customer-sidebar__nav-link">
            <li className="customer-sidebar__nav-item">
              <HomeIcon className="customer-sidebar__nav-icon" />
              <span className="customer-sidebar__nav-text">Home</span>
            </li>
          </Link>
          {/* <p className="title">LISTS</p> */}
          <Link
            to="/customer/dash/invoices"
            className="customer-sidebar__nav-link"
          >
            <li className="customer-sidebar__nav-item">
              <ReceiptIcon className="icon" />
              <span className="customer-sidebar__nav-text">Purchases</span>
            </li>
          </Link>

          <Link
            to="/customer/dash/profile"
            className="customer-sidebar__nav-link"
          >
            <li className="customer-sidebar__nav-item">
              <AccountCircleOutlinedIcon className="customer-sidebar__nav-icon" />
              <span className="customer-sidebar__nav-text"> Account</span>
            </li>
          </Link>
          <li onClick={logoutHandler} className="customer-sidebar__nav-item">
            <ExitToAppIcon className="customer-sidebar__nav-icon" />
            <span className="customer-sidebar__nav-text">SIGN OUT</span>
          </li>
        </ul>
      </div>
      <div className="customer-sidebar__footer">
        <div className="customer-sidebar__address">
          <p className="customer-sidebar__address-title">Shipping by air</p>
          <p className="customer-sidebar__address-text">
            {userData ? userData.customer.user.firstName : ""}{" "}
            {userData ? userData.customer.user.lastName : ""}
          </p>
          <p className="customer-sidebar__address-text">
            6858 NW 75th Street, Ste 4
          </p>
          <p className="customer-sidebar__address-text">
            {userData ? userData.customer.customerNumber : ""}
          </p>
          <p className="customer-sidebar__address-text">Miami, FL 33166</p>
        </div>
      </div>

      <div className="customer-sidebar__footer">
        <div className="customer-sidebar__address">
          <p className="customer-sidebar__address-title">Shipping by sea</p>
          <p className="customer-sidebar__address-text">
            {userData ? userData.customer.user.firstName : ""}{" "}
            {userData ? userData.customer.user.lastName : ""}{" "}
          </p>
          <p className="customer-sidebar__address-text">
            6858 NW 75th Street, Ste 5
          </p>
          <p className="customer-sidebar__address-text">
            {userData ? userData.customer.customerNumber : ""}
          </p>
          <p className="customer-sidebar__address-text">Miami, FL 33166</p>
        </div>
      </div>
    </div>
  );
};
export default NavAppCustomer;
