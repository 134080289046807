import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  LinearProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Define the default theme
const defaultTheme = createTheme();

// CSS styles for underlining links
const linkStyle = {
  textDecoration: "underline",
};

export default function PasswordResetEmail() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Make GET request with email as query parameter
      await axios.get(
        `/api/v1/auth/forgot/password?email=${encodeURIComponent(email)}`
      );

      setMessage("Password reset link sent to your email.");
    } catch (error) {
      setMessage(
        "Error sending reset link: " +
          (error.response?.data.message || error.message)
      );
      console.error("Error:", error);
    }

    setIsLoading(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <EmailIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            onSubmit={handleSubmit}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {isLoading ? "Sending..." : "Send Reset Link to email"}
            </Button>
            {isLoading && <LinearProgress />}
          </Box>
        </Box>
        {/* Change the alert style based on the message type */}
        {/* {message && (
          <div
            className={`alert ${
              message.includes("successful") ? "alert-success" : "alert-danger"
            }`}
            role="alert"
          >
            {typeof message === "string" ? message : <>{message}</>}
            {typeof message === "string" &&
              message.startsWith("Check your inbox for the reset link") && (
                <>
                  {" Please "}
                  <Link to="/login" variant="body2" style={linkStyle}>
                    click here to login
                  </Link>
                  {" with your new password."}
                </>
              )}
          </div>
        )} */}
      </Container>
    </ThemeProvider>
  );
}
